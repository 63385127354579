<template>
  <div class="GiftGrowth">
    <div class="GiftGrowth_nav">
      <van-tabs
        v-model="active"
        @click="onClick"
        color="blue"
        line-height="2px"
      >
        <van-tab title="成长权益使用记录">
          <div class="GiftGrowth_list" v-if="courseList.length">
            <div
              class="GiftGrowth_list_box"
              v-for="(item, index) in courseList"
              :key="index"
            >
              <div class="bottom">
                <van-image
                  class="top_left_img"
                  fit="cover"
                  lazy-load
                  :src="require('@/assets/img/SignIn_03.png')"
                >
                </van-image>
                <!-- item.tgrowthEquity.equityImageUrl || -->
                <div class="GiftGrowth_list_box_right">
                  <h3 class="van-multi-ellipsis--l2">
                    {{ item.tgrowthEquity.growthEquityName }}
                  </h3>
                  <p>
                    兑换时间：{{
                      item.thistoryInterests.eInsertTime.split("T")[0]
                    }}
                  </p>
                </div>
              </div>
              <div class="van-hairline--bottom"></div>
              <div class="top">
                <div class="top_right">
                  <van-icon name="points" />
                  <span>{{ item.thistoryInterests.hGeade }}</span>
                </div>
                <div class="top_left">
                  <!-- 兑换码： <span>{{ item.num }}</span> -->
                  <van-button
                    plain
                    hairline
                    round
                    type="info"
                    size="small"
                    @click="goCourse(item)"
                    >查看课程</van-button
                  >
                </div>
              </div>
            </div>
          </div>
          <van-empty description="暂无数据" v-else />
        </van-tab>
        <van-tab title="成长值获取记录">
          <div class="GiftGrowth_list" v-if="GrowthValue.length">
            <table class="obtain_table">
              <thead>
                <tr>
                  <th>
                    <van-icon
                      :name="require('@/assets/img/GrowthValueUse_01.png')"
                    />
                    <span>获取途径</span>
                  </th>
                  <th>
                    <van-icon
                      :name="require('@/assets/img/GrowthValueUse_02.png')"
                    />
                    <span>获取时间</span>
                  </th>
                  <th>
                    <van-icon
                      :name="require('@/assets/img/GrowthValueUse_03.png')"
                    />
                    <span>成长值</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in GrowthValue" :key="index">
                  <td>{{ item.title }}</td>
                  <td>{{ item.time }}</td>
                  <td>{{ item.num }}</td>
                  <!-- <td>{{ item.tgrowthActivity.activityName }}</td>
                 
                  <td class="td_blue">+{{ item.tgrowthActivity.growthNum }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <van-empty description="暂无数据" v-else />
        </van-tab>
      </van-tabs>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import {
  Image as VanImagen,
  Tab,
  Tabs,
  Button,
  Icon,
  Toast,
  Empty,
} from "vant";
import {
  findTHistoryInterestsByOpenId,
  findTHistoryIntegralByOpenId,
} from "@/api/SignIn";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImagen.name]: VanImagen,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
    copyrightIp,
  },
  data() {
    return {
      active: "",
      courseList: [],
      GrowthValue: [], //成长值获取记录
    };
  },
  created() {},
  mounted() {
    this.getFindTHistoryInterestsByOpenId();
  },
  methods: {
    onClick(name, title) {
      if (title == "成长权益使用记录") {
        this.getFindTHistoryInterestsByOpenId();
      } else {
        this.getFindTHistoryIntegralByOpenId();
      }
    },
    goCourse(data) {
      window.location.href = data.tgrowthEquity.linkUrl;
    },
    // 获取用户兑换历史记录
    getFindTHistoryInterestsByOpenId() {
      let openId = localStorage.getItem("code") || "";
      findTHistoryInterestsByOpenId({ openId }).then((res) => {
        this.courseList = res.data.data;
      });
    },
    // 获取用户成长值历史记录
    getFindTHistoryIntegralByOpenId() {
      let openId = localStorage.getItem("code") || "";
      findTHistoryIntegralByOpenId({ openId }).then((res) => {
        this.GrowthValue = [];
        res.data.data.forEach((element) => {
          this.GrowthValue.push({
            title:
              (element.tgrowthActivity &&
                element.tgrowthActivity.activityName) ||
              "",
            time: element.thistoryIntegral.gInsertTime.split("T")[0],
            num:
              (element.tgrowthActivity && element.tgrowthActivity.growthNum) ||
              "",
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.GiftGrowth_nav {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  background: #f9f9f9;
  // font-weight: bold;
  .van-tabs {
    width: 100%;
  }
  .GiftGrowth_list {
    width: 100%;
    border-top: 1px solid #e2e2e2;
    background: #fff;
    padding: 0 10px;

    .GiftGrowth_list_box {
      background: #fff;
      padding: 20px 15px 5px 15px;
      margin-bottom: 10px;
      .bottom {
        width: 100%;
        display: flex;
        margin: 10px 0;
        .top_left_img {
          width: 100px;
          height: 100px;
          min-width: 100px;
          border-radius: 8px;
          overflow: hidden;
        }
        .GiftGrowth_list_box_right {
          flex: 1;
          padding-left: 10px;
          h3 {
            font-size: 14px;
            margin: 6px 0 10px 0;
          }
          p {
            color: #999999;
            font-size: 12px;
          }
        }
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0;
        .top_left {
          display: flex;
          align-items: center;
          font-size: 16px;
          .van-button {
            // border-color: #999999;
            padding: 2px 10px 0 10px;
            margin-left: 10px;
          }
        }
        .top_right {
          span {
            color: #1989fa;
            padding-left: 6px;
            font-size: 18px;
          }
        }
      }
    }
    .obtain_table {
      width: 100%;

      background: #fff;
      thead {
        th {
          text-align: center;
          line-height: 35px;
          border-bottom: 1px solid #e6e6e6;
          color: #808492;
          span {
            padding-left: 3px;
          }
        }
      }
      tbody {
        td {
          text-align: center;
          line-height: 35px;
          border-bottom: 1px solid #f2f2f2;
        }
        .td_blue {
          color: #3d8df7;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
